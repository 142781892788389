import type { CustomOptionsProps } from '../../types';

export const customOptions: CustomOptionsProps = {
  languageCode: 'ja',
  regionISO: 'JP',
  cookieManagementEnabled: true,
  googleAdsEnabled: false,
  headerLiftEnabled: true,
  googleSearchConsoleId: '',
  googleTagManagerCode: '',
  googleAnalyticsId: 'G-XSZFJ54CM8',
  facebookTrackingId: '',
  twitterHandle: '@gamedistribution',
  twitterSiteHandle: '@azerion',
  appIconId: '2b395754-da2f-4518-9fd8-facb575ef4e7',
  headerLogoId: '3140814c-e2e0-4cfe-b3a5-9ebdba1c77e2',
  footerLogoId: '7d14b26a-bcf7-4749-8eb3-ff9dd4729959',
  fontUrl: 'https://fonts.googleapis.com/css2?family=Quicksand&family=Noto+Sans+JP&display=swap',
  defaultTitle: 'YJGames',
  titleTemplate: 'or-site-yjgames | %s',
  noFollow: true,
  noIndex: true,
  cookieLawProId: '',
  extraHeaderURL: '',
  extraFooterURL: '',
  extraHeaderStyleURL: '',
  extraFooterStyleURL: '',
  customCss: ``,
  scripts: [
    {
      src: 'https://cdn.cookielaw.org/consent/bbd4e696-968a-4e5e-8e06-64550484c17c/otSDKStub.js',
      strategy: 'afterInteractive',
      'data-domain-script': 'bbd4e696-968a-4e5e-8e06-64550484c17c'
    },
    {
      id: 'ot-callback-script',
      strategy: 'afterInteractive',
      dangerouslySetInnerHTML: {
        __html: `
            function OptanonWrapper() {
            };
          `
      }
    },
    {
      id: 'idhb', // Required by HeaderLift.
      src: 'https://hb.improvedigital.com/pbw/headerlift.min.js',
      strategy: 'afterInteractive'
    },
    {
      // Todo: Nunjucks the GA tracking IDs.
      src: `https://www.googletagmanager.com/gtag/js?id=G-XSZFJ54CM8`,
      strategy: 'afterInteractive'
    },
    {
      id: 'gtag-datalayer-script', // Required when using dangerouslySetInnerHTML.
      strategy: 'afterInteractive',
      dangerouslySetInnerHTML: {
        __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-XSZFJ54CM8', {
              page_path: window.location.pathname,
            });
          `
      }
    },
    {
      src: 'https://pm.azerioncircle.com/p/focus/',
      strategy: 'afterInteractive',
    },
  ],
  links: [
  ],
};